import React from 'react';

export const AppStateContext = React.createContext();

const isBrowser = typeof window !== 'undefined';

const localStorageKeys = {
  lang: 'ca.vabysmo.lang',
};

const getLocalStorageItem = key => {
  return isBrowser ? window.localStorage.getItem(key) : null;
};

const setLocalStorageItem = (key, value) => {
  isBrowser && window.localStorage.setItem(key, value);
};

const getDefaultLanguage = () => {
  if (isBrowser) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith('fr-')) {
      return 'french';
    }
  }

  return 'english';
};

export class AppStateProvider extends React.Component {
  constructor(props) {
    super(props);

    let language = getLocalStorageItem(localStorageKeys.lang);
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }

    this.state = {
      language: null,
      assetsLang: null,
      page: null,
      section: null,
      mobileMenuOpen: false,
    };
  }

  componentDidMount() {
    let language = getLocalStorageItem(localStorageKeys.lang);
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }

    this.setState({ language: language, assetsLang: language });
    this.loadLanguage(language);
  }

  getLanguage() {
    let language = getLocalStorageItem(localStorageKeys.lang);
    if (this.state && this.state.language) {
      language = this.state.language;
    }
    if (!language || !['english', 'french'].includes(language)) {
      language = getDefaultLanguage();
    }
    return language;
  }

  setLanguage = language => {
    if (!language || !['english', 'french'].includes(language)) {
      language = 'english';
    }

    this.setState({
      language: language,
    });

    localStorage.setItem(localStorageKeys.lang, language);
    this.loadLanguage(language);
  };

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === 'french' ? 'fr-ca' : 'en';
      window.OneTrust.changeLanguage(langCode);
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage;
      setTimeout(function () {
        loadLanguage(language, retryCount + 1);
      }, (2 + retryCount) * 50);
    }
  };

  setPage = page => {
    this.setState({
      page: page,
    });
  };

  setSection = section => {
    this.setState({
      section: section,
    });
  };

  toggleMobileMenu = () => {
    const mobileMenuOpen = this.state.mobileMenuOpen === false ? true : false;
    this.setState({
      mobileMenuOpen: mobileMenuOpen,
    });
  };

  closeMobileMenu = () => {
    setTimeout(() => {
      this.setState({
        mobileMenuOpen: false,
      });
    }, 50);
  };

  toggleLanguage = () => {
    const language = this.state.language === 'french' ? 'english' : 'french';
    this.setState({
      language: language,
      assetsLang: language,
    });
    setLocalStorageItem(localStorageKeys.lang, language);
    this.loadLanguage(language);
  };

  getValue = () => {
    return {
      ...this.state,
      setPage: this.setPage,
      setLanguage: this.setLanguage,
      getLanguage: this.getLanguage,
      setSection: this.setSection,
      toggleMobileMenu: this.toggleMobileMenu,
      closeMobileMenu: this.closeMobileMenu,
      toggleLanguage: this.toggleLanguage,
    };
  };

  render() {
    return <AppStateContext.Provider value={this.getValue()}>{this.props.children}</AppStateContext.Provider>;
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider>{element}</AppStateProvider>;
}
